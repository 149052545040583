<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> 參數設定 </b-breadcrumb-item>
            <b-breadcrumb-item active>
              {{ title }}
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-tabs>
      <b-tab title="參數設定" lazy> <parameter-form> </parameter-form> </b-tab>
      <b-tab title="功能啟用" lazy>
        <enable-feature-form></enable-feature-form>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { reactive } from "@vue/composition-api";
import { mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import orgNameMixin from "@/mixins/organizationName";
import ParameterForm from "./ParameterForm";
import EnableFeatureForm from "./EnableFeatureForm";
// import * as consts from "@/consts";

export default {
  components: {
    ParameterForm,
    EnableFeatureForm,
  },
  mixins: [orgNameMixin],
  setup() {
    const state = reactive({
      form: {},
    });
    const rules = {
      form: {},
    };

    const v$ = useVuelidate(rules, state);

    return { state, v$ };
  },
  data() {
    return {
      isLoading: false,
      isSubmmiting: false,
      form: {
        variables: [
          {
            id: null,
            value: null,
            key: "LINE_CHANNEL_TOKEN",
            name: "LINE TOKEN",
          },
          {
            id: null,
            value: null,
            name: "LINE OA ID (不含 @)",
            key: "LINE_OA_ID",
          },
          {
            id: null,
            value: "https://line.me/R/ti/p/@314dzjqt",
            key: "LINE_OA_URL",
            name: "LINE OA 網址",
          },
          {
            id: null,
            value: "1657463077-m5ne419M",
            key: "LINE_PORTAL_LIFF_ID",
            name: "LIFF ID",
          },
        ],
      },
      hasOa: false,
      merchantTypes: [],
      lineOaId: "",
      lineLiffId: "",
      keys: [
        "LINE_PORTAL_LIFF_ID",
        "LINE_CHANNEL_TOKEN",
        "LINE_OA_ID",
        "LINE_OA_URL",
      ],
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        type: { required },
        type_name: { required },
      },
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    // 是從「全系統管理」頁面進入修改
    isSystemSetting() {
      return ["OrgMerchantEdit", "OrgMerchantCreate"].includes(
        this.$route.name
      );
    },
    orgId() {
      return this.$store.state.general.organization.id;
    },
    title() {
      return "LINE 參數設定";
    },
    webhookHost() {
      return process.env.VUE_APP_WEBHOOK_HOST;
    },
    host() {
      return window.location.origin;
    },
    isLine() {
      return this.state.form.type === "LINE";
    },
  },
  mounted() {
    if (!this.hasPermission()) {
      this.$swal
        .fire({
          type: "error",
          text: "你沒有權限訪問此頁面",
          confirmButtonColor: "#d33",
        })
        .then(() => {});
    }
  },
  methods: {
    hasPermission() {
      return this.$permissions.has(
        this.$permissions.consts.LINE_SETTING_MODIFY
      );
    },
  },
};
</script>
